import { Component, Ref, Vue } from '@/lib/decorator';
import { GuiDatePicker } from '@library/gigant_ui';

@Component({})
export class CalendarMixin extends Vue {
  @Ref('datePicker') datePicker!: InstanceType<typeof GuiDatePicker>;
  dateNavigator: number | Date = 0;

  blockAddWeek = false;

  scrollWrapper: { scrollHeight: number; offsetHeight?: number } = { scrollHeight: 0, offsetHeight: 0 };
  layoutContent: any = {};
  calendarTopPosition = 0;
  calendarBottomPosition = 0;
  addClass = {
    arrowPrev: '',
    arrowNext: '',
  };
  currentDayShiftsStyle: { [key: string]: { day?: string; style?: string } } = {};

  $refs!: {
    calendarWrapper: HTMLElement;
    form: HTMLElement;
  };

  checkIsTodayOnDatePicker(dateMoment: moment.Moment): boolean {
    return dateMoment.isSame(this.dateNavigator, 'day');
  }

  handleScroll() {
    const scrollTop = window.pageYOffset || this.layoutContent.scrollTop;
    this.calendarBottomPosition =
      ((this.$refs.calendarWrapper as HTMLElement).offsetHeight ?? 0) + this.calendarTopPosition - window.innerWidth + 200;

    if (this.calendarBottomPosition <= scrollTop) {
      this.addClass.arrowNext = 'cp-absolute';
    } else {
      this.addClass.arrowNext = '';
    }

    if (this.calendarTopPosition + 100 <= scrollTop) {
      this.addClass.arrowPrev = 'cp-fixed';
    } else {
      this.addClass.arrowPrev = '';
    }
  }

  handleHover(e: { target: EventTarget | null }) {
    const currentElement = e.target as HTMLElement;
    const currentId: string = currentElement.getAttribute('data-id') as string;
    const currentDay: string = currentElement.getAttribute('data-day') as string;
    const dataShiftsPlace = currentElement.getAttribute('data-shifts');
    this.currentDayShiftsStyle = {};
    this.currentDayShiftsStyle[currentId] = {
      day: currentDay,
      style: currentElement.getAttribute('style') as string,
    };
    const maxWidth = 80;

    const shiftsByDay = document.querySelectorAll(`[data-shifts=${dataShiftsPlace}]`);

    if (shiftsByDay.length > 1) {
      const shiftsNearbyCurrent: { [key: string]: Element } = {};
      const shiftsNearbyCount = shiftsByDay.length - 1;

      let currentIndex = '';
      let index: string;
      let shift: Element;
      for ([index, shift] of Object.entries(shiftsByDay)) {
        const shiftId: string = shift.getAttribute('data-id') ?? '';
        const shiftDay = shift.getAttribute('data-day') ?? '';

        if (parseInt(shiftId ?? '0') === parseInt(currentId)) {
          currentIndex = index;
          continue;
        }

        this.currentDayShiftsStyle[shiftId] = {
          day: shiftDay,
          style: shift.getAttribute('style') ?? '',
        };
        shiftsNearbyCurrent[index] = shift;

        const newWidth = (100 - maxWidth) / shiftsNearbyCount;
        (shift as any).style.width = `calc(${newWidth}% - 1px)`;

        if (!currentIndex || parseInt(currentIndex) > parseInt(index)) {
          (shift as any).style.marginLeft = parseInt(index) * ((100 - maxWidth) / shiftsNearbyCount) + '%';
        } else {
          (shift as any).style.marginLeft =
            parseInt(index) * ((100 - maxWidth) / shiftsNearbyCount) + (maxWidth - newWidth) + '%';
        }

        shift.classList.add('cp-inner-hidden');
      }

      currentElement.style.width = `calc(${maxWidth}% - 1px)`;
      currentElement.style.marginLeft = parseInt(currentIndex) * ((100 - maxWidth) / shiftsNearbyCount) + '%';
      currentElement.classList.add('cp-inner-show');
    }
  }

  handleMouseLeave() {
    let shiftWrapper: HTMLElement | null;
    for (const [shiftId, shift] of Object.entries(this.currentDayShiftsStyle)) {
      shiftWrapper = document.querySelector(`[data-id='${shiftId}'][data-day='${(shift as any).day}']`);
      shiftWrapper?.setAttribute('style', (shift as any).style);
      shiftWrapper?.classList.remove('cp-inner-hidden');
      shiftWrapper?.classList.remove('cp-inner-show');
    }
  }

  selectToday(): void {
    if (this.datePicker) {
      this.blockAddWeek = true;
      this.datePicker.handler.selectToday();
      this.datePicker.handler.init();
      this.blockAddWeek = false;
    }
  }

  mounted(): void {
    this.scrollWrapper = document.getElementsByClassName('cp-calendar-scroll-container')[0];
    this.layoutContent = document.getElementsByClassName('cp-calendar-scroll-container')[0];

    if (this.$refs.calendarWrapper) {
      this.calendarTopPosition = (this.$refs.calendarWrapper as HTMLElement).offsetTop;
      this.layoutContent.addEventListener('scroll', this.handleScroll);
    }

    this.selectToday();
  }

  beforeUnmount(): void {
    this.layoutContent.removeEventListener('scroll', this.handleScroll);
  }

  updated(): void {
    if (this.$refs.calendarWrapper) {
      this.calendarTopPosition = (this.$refs.calendarWrapper as HTMLElement).offsetTop;
    }
  }

  unmounted(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
