
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import IconFont from '@/components/icons/IconFont.vue';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormTimePickerRange from '@/components/form/TimePicker/Range.vue';
import FormTimePickerActivatorsRange from '@/components/form/TimePicker/activators/Range.vue';

import EmployeesCalendarModule from '@/store/employees/calendar';
import { strings } from '@/lib/stringConst';
import SiteModule from '@/store/site';
import { intervalInterface, modalParamsInterface } from '@/store/employees/calendarModalParams';

@Component({
  components: {
    IconFont,
    ModalBtnBottom,
    FormButton,
    FormTimePickerRange,
    FormTimePickerActivatorsRange,
  },
  emits: ['closeModal'],
  beforeMount() {
    this.employeeId = this.$route.params.employeeId as string;
  },
})
export default class EmployeesCalendarModalEditReady extends Form {
  employeeId = '';

  get isBlock(): boolean {
    return SiteModule.isBlock;
  }

  get text(): Record<string, string> {
    return strings;
  }

  get modalParams(): modalParamsInterface {
    return EmployeesCalendarModule.modalParams;
  }

  get model(): { intervalStart: intervalInterface; intervalEnd: intervalInterface } {
    return EmployeesCalendarModule.modalParams.edit.model;
  }

  get interval(): { value: string; range: string[] } {
    const from = `${this.model.intervalStart.hour}:${this.model.intervalStart.minute}`;
    const to = `${this.model.intervalEnd.hour}:${this.model.intervalEnd.minute}`;
    const value = `${from}-${to}`;

    return {
      value,
      range: value.split('-'),
    };
  }

  returnBefore(): void {
    this.$emit('closeModal');
  }

  async selectTime(value: {
    to: { hour: string; minute: string; value: string };
    from: { hour: string; minute: string; value: string };
  }): Promise<void> {
    await EmployeesCalendarModule.updateEditIntervalStart({ type: 'hour', value: value.from.hour });
    await EmployeesCalendarModule.updateEditIntervalStart({ type: 'minute', value: value.from.minute });

    await EmployeesCalendarModule.updateEditIntervalEnd({ type: 'hour', value: value.to.hour });
    await EmployeesCalendarModule.updateEditIntervalEnd({ type: 'minute', value: value.to.minute });

    await EmployeesCalendarModule.updateHowMuchTime();
  }

  save(): void {
    if (this.isBlock) {
      return;
    }

    SiteModule.setIsBlock(true);
    EmployeesCalendarModule.updateReadyTimeInfo(this.employeeId);
  }

  deleteReadyTime(): void {
    EmployeesCalendarModule.deleteReadyTimeById(this.employeeId);
  }
}
