
import { Component, Vue } from '@/lib/decorator';
import DatePicker from '@/components/form/DatePickerIcon.vue';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormTimePickerRange from '@/components/form/TimePicker/Range.vue';
import FormTimePickerActivatorsRange from '@/components/form/TimePicker/activators/Range.vue';

import EmployeesCalendarModule from '@/store/employees/calendar';
import { strings } from '@/lib/stringConst';
import {
  dateInterface,
  includeExcludeInterface,
  intervalInterface,
  modalParamsInterface,
} from '@/store/employees/calendarModalParams';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  components: {
    GuiFormCheckbox,
    DatePicker,
    ModalBtnBottom,
    FormButton,
    FormTimePickerRange,
    FormTimePickerActivatorsRange,
  },
  emits: ['closeModal'],
  beforeMount() {
    this.employeeId = this.$route.params.employeeId as string;
  },
})
export default class EmployeesCalendarModalDelete extends Vue {
  employeeId = '';
  isTimeInterval = this.model.isTimeInterval.list[0].checked ?? false;
  isExcludeWeekday = true;
  isExcludeWeekends = true;
  isExcludeShifts = false;

  get text(): Record<string, string> {
    return strings;
  }

  get modalParams(): modalParamsInterface {
    return EmployeesCalendarModule.modalParams;
  }

  get model(): {
    dateStart: dateInterface;
    dateEnd: dateInterface;
    isTimeInterval: includeExcludeInterface;
    intervalStart: intervalInterface;
    intervalEnd: intervalInterface;
    isExcludeWeekday: includeExcludeInterface;
    isExcludeWeekends: includeExcludeInterface;
    isExcludeShifts: includeExcludeInterface;
  } {
    return EmployeesCalendarModule.modalParams.delete.model;
  }

  get interval(): { value: string; range: string[] } {
    const from = `${this.model.intervalStart.hour}:${this.model.intervalStart.minute}`;
    const to = `${this.model.intervalEnd.hour}:${this.model.intervalEnd.minute}`;
    const value = `${from}-${to}`;

    return {
      value,
      range: value.split('-'),
    };
  }

  updateCheckbox(value: string, params: { handler: string; name: string }): void {
    this.$store.dispatch(params.handler, { name: params.name, id: 1, bool: Boolean(value) });
  }

  returnBefore(): void {
    this.$emit('closeModal');
  }

  save(): void {
    EmployeesCalendarModule.deleteShiftWarning({
      employeeId: this.employeeId,
      handler: 'employeeShifts/deleteShift',
    });
  }

  selectDateStart(date: { day: string; month: string; year: string }): void {
    EmployeesCalendarModule.updateDeleteDateStart(`${date.day}.${date.month}.${date.year}`);
  }

  selectDateEnd(date: { day: string; month: string; year: string }): void {
    EmployeesCalendarModule.updateDeleteDateEnd(`${date.day}.${date.month}.${date.year}`);
  }

  selectTime(value: { from: { hour: string; minute: string }; to: { hour: string; minute: string } }): void {
    EmployeesCalendarModule.updateDeleteIntervalStart({ type: 'hour', value: value.from.hour });
    EmployeesCalendarModule.updateDeleteIntervalStart({ type: 'minute', value: value.from.minute });
    EmployeesCalendarModule.updateDeleteIntervalEnd({ type: 'hour', value: value.to.hour });
    EmployeesCalendarModule.updateDeleteIntervalEnd({ type: 'minute', value: value.to.minute });
  }
}
