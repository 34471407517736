
import { Component } from '@/lib/decorator';
import { GuiDatePicker } from '@library/gigant_ui';
import FormButton from '@/components/form/Button/index.vue';
import { CalendarMixin } from '@/components/calendar/calendarMixin';

import EmployeesCalendarModule, { StyleInterface } from '@/store/employees/calendar';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import UserModule from '@/store/user';
import { ShiftInterface } from '@/interfaces/shift.interface';

library.add(faChevronUp, faChevronDown);

@Component({
  components: { FormButton, GuiDatePicker },
})
export default class EmployeesCalendar extends CalendarMixin {
  get text(): Record<string, string | string[]> {
    return EmployeesCalendarModule.text;
  }

  get canUserEditReadyTime() {
    return UserModule.userHasPermission('CAN_EDIT_EMPLOYEE_SHIFT');
  }

  get calendar() {
    return this.$store.getters['employeeShifts/calendarInOrder'];
  }

  get hours(): {} {
    return EmployeesCalendarModule.hours;
  }

  get calendarStyleByWeeks(): Record<string, StyleInterface> {
    return EmployeesCalendarModule.calendarStyleByWeeks;
  }

  addNew(date = ''): void {
    EmployeesCalendarModule.modalInit({ type: 'add', date });
    EmployeesCalendarModule.showModal('add');
  }

  handleClickOnCalendarSlot(shift: ShiftInterface): void {
    if (shift.marketId) {
      return this.$emit('editShift', shift);
    }

    if (shift.entityType === 'ready') {
      EmployeesCalendarModule.setEditEntityType(shift.entityType);
      EmployeesCalendarModule.setShiftModalTitle(shift.start);
      EmployeesCalendarModule.modalInit({
        type: 'edit',
        shiftId: shift.id.toString(),
        start: shift.start,
        end: shift.end,
        howMuchTime: shift.howMuchTime as string,
      });

      EmployeesCalendarModule.showModal('edit');
    }
  }

  deleteFromShifts(): void {
    EmployeesCalendarModule.modalInit({ type: 'delete' });
    EmployeesCalendarModule.showModal('delete');
  }

  async handleDatePick(date: Date): Promise<void> {
    if (this.blockAddWeek) {
      return;
    }

    try {
      const employeeId = this.$route.params.employeeId as string;
      this.blockAddWeek = true;

      await EmployeesCalendarModule.getWeekByDate({ date, employeeId });

      if (this.addClass.arrowPrev.length > 0) {
        this.layoutContent.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    } finally {
      this.blockAddWeek = false;
    }
  }

  async handlePrev(): Promise<void> {
    if (this.addClass.arrowPrev.length > 0) {
      this.layoutContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      return;
    }

    if (this.blockAddWeek) {
      return;
    }

    try {
      this.blockAddWeek = true;

      const employeeId = this.$route.params.employeeId as string;
      await EmployeesCalendarModule.getPrevWeek(employeeId);
    } finally {
      this.blockAddWeek = false;
    }
  }

  async handleNext(): Promise<void> {
    if (this.addClass.arrowNext.length === 0) {
      const limit = Math.max(this.scrollWrapper.scrollHeight, this.scrollWrapper.offsetHeight ?? 0);
      this.layoutContent.scrollTo({
        top: limit,
        behavior: 'smooth',
      });

      return;
    }

    if (this.blockAddWeek) {
      return;
    }

    try {
      this.blockAddWeek = true;

      const employeeId = this.$route.params.employeeId as string;
      await EmployeesCalendarModule.getNextWeek(employeeId);

      const limit = Math.max(this.scrollWrapper.scrollHeight, this.scrollWrapper.offsetHeight ?? 0);
      this.layoutContent.scrollTo({
        top: limit,
        behavior: 'smooth',
      });
    } finally {
      this.blockAddWeek = false;
    }
  }

  getStyle(
    indexWeek: string,
    deviation: string,
    field: 'weekStyle' | 'hourGrid' | 'min' | 'max'
  ): StyleInterface | undefined {
    const index: string = (parseInt(indexWeek) + parseInt(deviation)).toString();
    if (index in this.calendarStyleByWeeks) {
      return (this.calendarStyleByWeeks[index][field] as unknown) as StyleInterface;
    }
  }
}
