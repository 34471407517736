import { defineComponent } from 'vue';
import moment from 'moment/moment.js';
import { destructuringDateString, compareDate } from '@/lib/Utils';

export interface DatePickerValue {
  day: string;
  month: string;
  year: string;
}

export default defineComponent({
  emits: ['click', 'init', 'validate-error'],
  props: {
    // data: { //TODO:: GA-1148
    //     type: Object,
    //     default: function() {
    //         return {value: null}
    //     }
    // },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    separator: {
      type: String,
      default: '/',
    },
    valuePath: {
      type: String,
      default: 'value',
    },
    dateFormat: {
      type: String,
      default: 'DD/MM/YY',
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      isVisible: false,
      blockChangeVisible: false,
      dayOfWeeks: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
      month: {
        month: '',
        year: '',
        name: '',
        startOfMonth: '',
        endOfMonth: '',
        weeks: null,
        currentDay: null,
        number: '',
      },
      currentValue: {
        day: '',
        month: '',
        year: '',
        monthMMM: '',
      },
    };
  },
  computed: {
    visible: {
      set(value: boolean) {
        if (this.blockChangeVisible) return;

        this.isVisible = value;

        if (value) {
          this.blockChangeVisible = true;
          setTimeout(() => {
            this.blockChangeVisible = false;
          }, 0);
        }
      },
      get(): boolean {
        return this.isVisible;
      },
    },
  },
  watch: {
    'data.value': {
      immediate: true,
      handler: function () {
        this.init();
      },
    },
  },
  methods: {
    documentClick(e: MouseEvent) {
      const el: HTMLElement = this.$refs.datePickerDefault as HTMLElement;
      const target = e.target;
      if (this.visible) {
        if (el && el !== target && !el.contains(target as HTMLElement)) {
          this.visible = false;
        }
      }
    },

    isSelectedDay(params: { day: string; month: string; year: string }) {
      return (
        parseInt(params.day) === parseInt(this.currentValue.day) &&
        parseInt(params.month) === parseInt(this.currentValue.month) &&
        parseInt(params.year) === parseInt(this.currentValue.year)
      );
    },

    validate(params: { day: string; month: string; year: string }) {
      const date = `${params.day}/${params.month}/${params.year}`;
      const { min, max } = this;

      // @ts-ignore
      return (
        (!min || compareDate(date, min, this.dateFormat) > -1) &&
        // @ts-ignore
        (!max || compareDate(max, date, this.dateFormat) > -1)
      );
    },

    updateSelectValue(params: { day: string; month: string; year: string; monthMMM: string }) {
      if (params.day) {
        this.currentValue.day = params.day;
      }
      if (params.month) {
        this.currentValue.month = params.month;
      }
      if (params.year) {
        this.currentValue.year = params.year;
      }
      if (params.monthMMM) {
        this.currentValue.monthMMM = params.monthMMM;
      }

      this.$emit('validate-error', false);
    },

    getPageMonth(params: { monthNumber: string; yearNumber: string }) {
      const month = params.monthNumber.padStart(2, '0');
      const momentObject = moment(`01/${month}/${params.yearNumber}`, this.dateFormat);

      const monthName = momentObject.format('MMMM');

      const year = momentObject.format('YYYY');

      const startOfMonth = momentObject.startOf('month').date();

      const endOfMonth = momentObject.endOf('month').date();

      const weeks: Record<string, Record<string, number>> = {};
      let weekOfYear, dayOfWeek;
      for (let index = startOfMonth; index <= endOfMonth; index++) {
        const tmpMoment = moment(`${index}/${month}/${params.yearNumber}`, this.dateFormat);

        weekOfYear = 'w' + tmpMoment.week();

        dayOfWeek = tmpMoment.isoWeekday();

        if (weeks[weekOfYear]) {
          weeks[weekOfYear][dayOfWeek] = index;
        } else {
          weeks[weekOfYear] = {};
          weeks[weekOfYear][dayOfWeek] = index;
        }
      }

      (this.month as any) = {
        number: momentObject.format('M'),
        year: params.yearNumber.toString(),
        name: `${monthName} ${year}`,
        startOfMonth: startOfMonth.toString(),
        endOfMonth: endOfMonth.toString(),
        weeks,
      };
    },

    init() {
      // @ts-ignore
      const { day, month, year } = destructuringDateString(this.data.value, this.separator);

      if (!day || !month || !year) return;

      this.getPageMonth({
        monthNumber: month,
        yearNumber: year,
      });

      this.updateSelectValue({
        day,
        month,
        year,
        // @ts-ignore
        monthMMM: moment(`${day}/${month}/${year}`, this.dateFormat).format('MMM').replace('.', ''),
      });
    },

    toggleVisible(flag: boolean) {
      // @ts-ignore
      if (this.disabled) {
        return;
      }

      // @ts-ignore
      if (this.data.value.indexOf(this.separator) === -1) {
        // @ts-ignore
        this.data.value = moment().format(this.dateFormat);
        this.init();

        this.$emit('init', this.currentValue);
      }

      setTimeout(() => {
        // @ts-ignore
        if (moment(this.data.value, this.dateFormat).isValid()) {
          this.visible = flag || !this.visible;
        }
      });
    },

    selectValue(dayNumber: string) {
      if (!dayNumber) {
        return;
      }

      const day = String(dayNumber).padStart(2, '0');
      const month = String(this.month.number).padStart(2, '0');
      const year = String(this.month.year);
      // @ts-ignore
      const monthMMM = moment(`${day}/${month}/${year}`, this.dateFormat).format('MMM').replace('.', '');

      const newValue = { day, month, year, monthMMM };

      if (this.validate(newValue)) {
        this.visible = false;

        this.$emit('click', newValue);

        this.updateSelectValue(newValue);
      }
    },

    next() {
      const year = parseInt(this.month.year);
      const month = parseInt(this.month.number);

      if (month === 12) {
        this.getPageMonth({
          monthNumber: '1',
          yearNumber: String(year + 1),
        });

        return;
      }

      this.getPageMonth({
        monthNumber: String(month + 1),
        yearNumber: String(year),
      });
    },

    prev() {
      const year = parseInt(this.month.year);
      const month = parseInt(this.month.number);

      if (month === 1) {
        this.getPageMonth({
          monthNumber: '12',
          yearNumber: String(year - 1),
        });

        return;
      }

      this.getPageMonth({
        monthNumber: String(month - 1),
        yearNumber: String(year),
      });
    },
  },
  created() {
    document.body.addEventListener('click', this.documentClick);
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.documentClick);
  },
});
