<template>
  <div ref="datePickerDefault" class="cp-date-picker-icon__block">
    <div class="cp-date-picker-icon__wrapper" @click="toggleVisible">
      <span class="cp-date-picker-icon__bg"></span>

      <span class="cp-date-picker__month">{{ currentValue.monthMMM }}</span>
      <span class="cp-date-picker__day">{{ currentValue.day }}</span>

      <div class="cp-picker-date__wrapper">
        <div class="cp-picker-date__bg"></div>

        <picker-date class="cp-date-picker-icon" />
      </div>
    </div>

    <div v-if="visible" class="mr-calendar__select-wrapper cp-input-radius-25">
      <div :key="`month_${data.date}`" class="mr-calendar__select-month cp-input-radius-25">
        <font-awesome-icon class="cp-calendar__arrow-left" size="lg" :icon="['fas', 'chevron-left']" @click="prev" />
        <span>{{ month.name }}</span>
        <font-awesome-icon class="cp-calendar__arrow-right" size="lg" :icon="['fas', 'chevron-right']" @click="next" />
      </div>

      <div class="mr-calendar__day-weeks">
        <table class="mr-calendar__days-table mr-calendar__days-table--header">
          <thead>
            <tr>
              <th v-for="day in dayOfWeeks" :key="`${data.key}_${day}`">{{ day }}</th>
            </tr>
          </thead>
        </table>

        <table class="mr-calendar__days-table cp-input-radius-25">
          <tbody>
            <tr v-for="(week, weekOfYear) in month.weeks" :key="weekOfYear">
              <td
                v-for="dayOfWeek in 7"
                :key="`${data.key}_${weekOfYear}_${dayOfWeek}`"
                class="cp-calendar__day"
                :class="{
                  active: week[dayOfWeek] && isSelectedDay({ day: week[dayOfWeek], month: month.number, year: month.year }),
                  'not-active': !week[dayOfWeek],
                }"
                @click="selectValue(week[dayOfWeek])"
              >
                <div class="cp-calendar__day-wrapper">
                  {{ week[dayOfWeek] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PickerDate from '@/components/icons/PickerDate.vue';
import DatePickerMixin from '@/components/form/DatePickerMixin';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronLeft, faChevronRight);

export default {
  components: {
    PickerDate,
  },
  mixins: [DatePickerMixin],
  props: {
    data: {
      type: Object,
      default() {
        return {
          key: 'date',
          type: 'date-picker',
          title: 'С даты',
          placeholder: '',
          value: '21.02.2020',
          action: 'user/auth/updateLogin',
          required: true,
          error: {
            class: '',
            message: '',
          },
          validation: {
            empty: '',
            incorrect: '',
          },
        };
      },
    },
    disabled: {
      type: Boolean,
    },
    separator: {
      type: String,
      default: '.',
    },
    dateFormat: {
      type: String,
      default: 'DD/MM/YYYY',
    },
  },
};
</script>
