
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormTimePickerRange from '@/components/form/TimePicker/Range.vue';
import MultiDatePicker from '@/components/form/MultiDatePicker/index.vue';
import FormTimePickerActivatorsRange from '@/components/form/TimePicker/activators/Range.vue';

import EmployeesCalendarModule from '@/store/employees/calendar';
import { strings } from '@/lib/stringConst';
import SiteModule from '@/store/site';
import { modalParamsAddModelInterface, modalParamsInterface } from '@/store/employees/calendarModalParams';
import moment from 'moment';

@Component({
  components: {
    ModalBtnBottom,
    FormButton,
    FormTimePickerRange,
    FormTimePickerActivatorsRange,
    MultiDatePicker,
  },
  emits: ['closeModal'],
  beforeMount() {
    this.employeeId = this.$route.params.employeeId as string;
  },
})
export default class EmployeesCalendarModalAdd extends Form {
  employeeId = '';
  includeWeekdays = true;
  includeWeekends = true;

  currentMoment = moment();
  minDate = this.currentMoment.format('YYYY-MM-DD');
  maxDate = this.currentMoment.clone().startOf('month').add(1, 'year').add(-1, 'day').format('YYYY-MM-DD');

  get isBlock(): boolean {
    return SiteModule.isBlock;
  }

  get text(): Record<string, string> {
    return strings;
  }

  get modalParams(): modalParamsInterface {
    return EmployeesCalendarModule.modalParams;
  }

  get model(): modalParamsAddModelInterface {
    return EmployeesCalendarModule.modalParams.add.model;
  }

  get interval(): { value: string; range: string[] } {
    const from = `${this.model.intervalStart.hour}:${this.model.intervalStart.minute}`;
    const to = `${this.model.intervalEnd.hour}:${this.model.intervalEnd.minute}`;
    const value = `${from}-${to}`;

    return {
      value,
      range: value.split('-'),
    };
  }

  returnBefore(): void {
    this.$emit('closeModal');
  }

  selectTime(value: {
    to: { hour: string; minute: string; value: string };
    from: { hour: string; minute: string; value: string };
  }): void {
    EmployeesCalendarModule.updateIntervalStart({ type: 'hour', value: value.from.hour });
    EmployeesCalendarModule.updateIntervalStart({ type: 'minute', value: value.from.minute });
    EmployeesCalendarModule.updateIntervalEnd({ type: 'hour', value: value.to.hour });
    EmployeesCalendarModule.updateIntervalEnd({ type: 'minute', value: value.to.minute });
  }

  selectDates(dates: string[]): void {
    EmployeesCalendarModule.updateDates(dates);
  }

  selectDateStart(date: { day: string; month: string; year: string }): void {
    EmployeesCalendarModule.updateDateStart(`${date.day}.${date.month}.${date.year}`);
  }

  selectDateEnd(date: { day: string; month: string; year: string }): void {
    EmployeesCalendarModule.updateDateEnd(`${date.day}.${date.month}.${date.year}`);
  }

  async save(): Promise<void> {
    if (this.isBlock) {
      return;
    }

    SiteModule.setIsBlock(true);

    await EmployeesCalendarModule.addNewShift({
      employeeId: this.employeeId,
      data: {
        includeWeekdays: this.includeWeekdays ? 1 : 0,
        includeWeekends: this.includeWeekends ? 1 : 0,
      },
    });
  }
}
